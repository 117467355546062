import * as React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => {
    return (
        <Layout>
            <main>
                <title>Page Not found</title>
                <h1>This page is not available</h1>
            </main>
        </Layout>
    );
};

export default NotFoundPage;
